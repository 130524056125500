import React, { ReactNode } from "react";

import {
  ChevronLeft,
  ChevronRight,
} from "@hl/base-components/lib/assets/icons/HDS";
import { useNavigateSafe } from "@hl/shared-features/lib/hooks/navigate";
import useSwipe from "@hl/shared-features/lib/hooks/useSwipe";
import { Box, createStyles } from "@mantine/core";
import { Link } from "react-router-dom";

import { buildTokenNavigationUrl } from "~features/token-details/utils";

const useStyles = createStyles(
  (theme, { direction }: { direction?: "left" | "right" }) => ({
    link: {
      background: theme.colors.baseBackground[0],
      width: 44,
      height: 44,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      position: "absolute",
      top: "50%",
      ...(direction === "right" && { right: -6 }),
      ...(direction === "left" && { left: -6 }),
      color: "inherit",
      textDecoration: "none",
      boxShadow: theme.shadows.sm,
    },
  })
);

const NavigationArrow = ({
  collection,
  prevTokenId,
  nextTokenId,
  showNavigation,
  children,
  persistNavigation,
}: {
  collection?: { id: string; onchainId: string };
  nextTokenId?: string | null;
  prevTokenId?: string | null;
  showNavigation?: boolean;
  children: ReactNode;
  persistNavigation?: boolean;
}) => {
  const navigate = useNavigateSafe();
  const swipeHandlers = useSwipe({
    onSwipedLeft: () =>
      nextTokenId &&
      collection &&
      navigate(buildTokenNavigationUrl(collection, nextTokenId)),
    onSwipedRight: () =>
      prevTokenId &&
      collection &&
      navigate(buildTokenNavigationUrl(collection, prevTokenId)),
  });

  if (!showNavigation) {
    return <>{children}</>;
  }

  return (
    <Box pos="relative" h="inherit" w="inherit" {...swipeHandlers}>
      {children}
      {showNavigation && (prevTokenId || persistNavigation) && (
        <TokenNavigationLink
          direction="left"
          url={
            prevTokenId && collection
              ? buildTokenNavigationUrl(collection, prevTokenId)
              : ""
          }
        />
      )}
      {showNavigation && (nextTokenId || persistNavigation) && (
        <TokenNavigationLink
          direction="right"
          url={
            nextTokenId && collection
              ? buildTokenNavigationUrl(collection, nextTokenId)
              : ""
          }
        />
      )}
    </Box>
  );
};

const TokenNavigationLink = ({
  direction,
  url,
}: {
  direction?: "left" | "right";
  url: string;
}) => {
  const { classes } = useStyles({ direction });
  return (
    <Link
      to={url}
      className={classes.link}
      style={{
        pointerEvents: url ? "auto" : "none",
        opacity: url ? 1 : 0.5,
        transition: "opacity 250ms",
      }}
    >
      {direction === "left" ? (
        <ChevronLeft height={22} width={22} />
      ) : (
        <ChevronRight height={22} width={22} />
      )}
    </Link>
  );
};

export default NavigationArrow;
